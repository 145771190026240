import React, { useState } from "react";
import MyDesktop from "../Components/MyDesktop";
import MyMobile from "../Components/MyMobile";

const Navbar = () => {
  const [drawer, setDrawer] = useState(false);

  const changeDrawer = () => {
    setDrawer(!drawer);
  };

  return (
    <>
      <MyDesktop>
        <div className="px-4 py-2 fixed-top bg-white navbar-shadow">
          <div className="d-flex justify-content-between p-3 fw-bolder fs-5">
            <div className="pointer fs-2">Anuj.dev</div>
            <div className="d-flex align-items-center gap-5">
              <div className="d-flex align-items-center">
                <div className="px-3">
                  <a href="#home">Home</a>
                </div>
                <div className="px-3">
                  <a href="#about">About</a>
                </div>
                <div className="px-3">
                  <a href="#project">Project</a>
                </div>
                <div className="px-3">
                  <a href="#contact">Contact</a>
                </div>
              </div>

              <div
                class="cogig-hire-me-button"
                data-userId="3a2156b3-68dd-4ee0-bc53-fe6a6587580a"
                data-theme="dark"
                data-img="avtar/001_d92aI9Z.jpg"
              ></div>
            </div>
          </div>
        </div>
      </MyDesktop>

      <MyMobile>
        <div
          className="d-flex text-center align-items-center justify-content-between 
        fw-bolder px-4 py-3 fs-4 fixed-top bg-white navbar-shadow"
        >
          <div className="">Anuj.dev</div>
          <div
            class="cogig-hire-me-button"
            data-userId="3a2156b3-68dd-4ee0-bc53-fe6a6587580a"
            data-theme="dark"
            data-img="avtar/001_d92aI9Z.jpg"
          ></div>
          <div className="fs-2" onClick={changeDrawer}>
            <i className="fas fa-bars"></i>
          </div>
        </div>
        <div
          className={
            drawer
              ? "side-drawer open fs-3 fw-semibold d-flex flex-column align-items-center justify-content-center"
              : "side-drawer fs-3 fw-semibold d-flex flex-column align-items-center justify-content-center"
          }
        >
          <span className="text-end fs-1 close" onClick={changeDrawer}>
            <i className="fas fa-times"></i>
          </span>
          <div className="py-4">
            <a href="#home" onClick={changeDrawer}>
              Home
            </a>
          </div>
          <div className="py-4">
            <a href="#about" onClick={changeDrawer}>
              About
            </a>
          </div>
          <div className="py-4">
            <a href="#project" onClick={changeDrawer}>
              Project
            </a>
          </div>
          <div className="py-4">
            <a href="#contact" onClick={changeDrawer}>
              Contact
            </a>
          </div>
        </div>
      </MyMobile>
    </>
  );
};

export default Navbar;
