import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './app/Assets/css/style.scss';
import Navbar from './app/Screen/Navbar';
import Home from './app/Screen/Home';
import About from './app/Screen/About';
import Projects from './app/Screen/Projects';
import Contact from './app/Screen/Contact';

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <About />
      <Projects />
      <Contact />
    </>
  );
}

export default App;
