import React from "react";

const Contact = () => {
  return (
    <>
      <div className="container p-lg-5 p-4" id="contact">
        <div className="th-20 text-primary fw-bold">Contact</div>
        <div className="th-24 mt-2 fw-bold">Hit me up! 👇</div>
        <div className="row mt-4">
          <div className="col-lg-4 d-flex align-items-center">
            <div className="svg th-br-50">
              <i className="fas fa-envelope-open-text th-30 text-primary"></i>
            </div>
            <div className="m-3">
              <div className="th-18 fw-bold">Mail</div>
              <div className="pointer th-16">
                <a href="mailto:anujkumar962001@gmail.com">
                  anujkumar962001@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-center">
            <div className="svg th-br-50">
              <i className="fas fa-map-marked-alt th-30 text-primary"></i>
            </div>
            <div className="m-3">
              <div className="th-18 fw-bold">Location</div>
              <a href="https://www.google.com/maps/place/Kangra,+Himachal+Pradesh+176001/@32.1030046,76.2551163,14z/data=!3m1!4b1!4m6!3m5!1s0x391b458198590ddf:0xad8e3ff3dfe5b1fe!8m2!3d32.0998031!4d76.2691006!16zL20vMDhrOHgz">
                <div className="pointer th-16">Himachal Pradesh, India</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright */}

      <div className="bg-black">
        <div className="container p-3">
          <div className="row align-items-center">
            <div className="col-lg-5 text-white th-17 fw-bold text-center">
              Copyright © 2023. All rights are reserved
            </div>
            <div className="col-lg-4"></div>
            <div className="col-lg-3 text-center mt-lg-0 mt-2">
              <span className="mx-2">
                <a
                  href="https://www.linkedin.com/in/anuj-kumar-3979661b5/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="fs-2">
                    <i className="fab fa-linkedin text-white"></i>
                  </span>
                </a>
              </span>
              <span className="mx-2">
                <a
                  href="https://github.com/anujkumar09062001"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="fs-2">
                    <i className="fab fa-github text-white"></i>
                  </span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
