import React from "react";
import Projectcms from "../Assets/img/projectcms.jpg";
import CarRental from "../Assets/img/Car-Rental.png";
import dwiqr from "../Assets/img/dwiqr.jpg";
import dwilogo from "../Assets/img/dwilogo.jpg";
import dwiwelcome from "../Assets/img/dwiwelcome.jpg";

const Projects = () => {
  return (
    <div className="" style={{ backgroundColor: "#f9f9f9" }} id="project">
      <div className="container p-lg-5 p-4">
        <div className="text-primary th-20 fw-bold">PORTFOLIO</div>
        <div className="th-24 fw-bold mt-2">
          Each project is a unique piece of development 🧩
        </div>
        <div className="row bg-white p-4 my-5 th-br-20">
          <div className="col-lg-6 shadow-card p-0 th-br-20">
            <div
              className="th-br-15"
              style={{ height: "300px", overflow: "hidden" }}
            >
              <a
                href="https://carrental.anujkumar.site/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={CarRental}
                  alt="Project CMS"
                  className="anujimage shadow-card car-rental-img"
                />
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="text-center px-lg-5 px-2 mt-lg-0 mt-5">
              <div className="th-20 fw-bold">Car Rental</div>
              <div className="mt-1 th-18 text-secondary">
                A car rental website is an online platform that allows users to
                rent cars for personal or business use. The website provides an
                interface for searching, comparing, and reserving cars.
              </div>
              <div className="mt-5 fw-bold th-18">
                <span className="svg th-br-8" style={{ padding: "15px" }}>
                  React
                </span>
                <span className="svg th-br-8" style={{ padding: "15px" }}>
                  Bootstrap
                </span>
              </div>
              <div className="mt-5 fw-semibold">
                <a
                  href="https://github.com/anujkumar09062001/car-rental"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="th-20 me-5">
                    Code <i className="fab fa-github text-dark"></i>
                  </span>
                </a>
                <a
                  href="https://carrental.anujkumar.site/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="th-20">
                    Live Demo{" "}
                    <i className="fas fa-external-link-alt text-dark"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row bg-white p-4 my-5 th-br-20 shadow-card">
          <div className="col-lg-6">
            <a
              href="https://cms.anujkumar.site/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Projectcms}
                alt="Project CMS"
                className="anujimage shadow-card th-br-15"
              />
            </a>
          </div>
          <div className="col-lg-6">
            <div className="text-center px-lg-5 px-2 mt-lg-0 mt-5">
              <div className="th-20 fw-bold">COLLEGE MANAGEMENT SYSTEM</div>
              <div className="mt-1 th-18 text-secondary">
                A college management system is a dashboard where one can manage
                their colllege data easily. The dashboard provides an interface
                for searching and adding college related and students data.
              </div>
              <div className="mt-4 fw-bold th-18">
                <span className="svg th-br-8" style={{ padding: "15px" }}>
                  React
                </span>
                <span className="svg th-br-8" style={{ padding: "15px" }}>
                  Django
                </span>
              </div>
              <div className="mt-5 fw-semibold">
                <a
                  href="https://github.com/anujkumar09062001/cms-frontend"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="th-20 me-5">
                    Code <i className="fab fa-github text-dark"></i>
                  </span>
                </a>
                <a
                  href="https://cms.anujkumar.site/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="th-20">
                    Live Demo{" "}
                    <i className="fas fa-external-link-alt text-dark"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row bg-white p-4 my-5 th-br-20 shadow-card align-items-center">
          <div className="col-lg-2">
            <div className="text-center mx-lg-3 mx-2 mt-lg-0 mt-5 shadow-card">
              <img src={dwilogo} alt="dwilogo" height="350px" />
            </div>
          </div>
          <div className="col-lg-2">
            <div className="text-center mx-lg-4 mx-2 mt-lg-0 mt-5 shadow-card">
              <img src={dwiwelcome} alt="dwiwelcome" height="350px" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="text-center px-lg-5 px-2 mt-lg-0 mt-5">
              <div className="th-20 fw-bold">Online Selling Platform</div>
              <div className="mt-2 th-18 text-secondary">
                App know as DoneWithIt which is primarily made for selling items
                online that are no more useful for us in our daily life.
              </div>
              <div className="mt-2 fw-bold th-14 row">
                <div className="col-6">
                  <span
                    className="svg th-br-8 w-100 d-block"
                    style={{ padding: "10px" }}
                  >
                    ReactNative
                  </span>
                </div>
                <div className="col-6">
                  <span
                    className="svg th-br-8 w-100 d-block"
                    style={{ padding: "10px" }}
                  >
                    Nodejs
                  </span>
                </div>
                <div className="col-6">
                  <span
                    className="svg th-br-8 w-100 d-block"
                    style={{ padding: "10px" }}
                  >
                    Expressjs
                  </span>
                </div>
                <div className="col-6">
                  <span
                    className="svg th-br-8 w-100 d-block"
                    style={{ padding: "10px" }}
                  >
                    MongoDB
                  </span>
                </div>
              </div>
              <div className="mt-4 fw-semibold">
                <a
                  href="https://github.com/anujkumar09062001/DoneWithIt-frontend"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="th-20">
                    Code <i className="fab fa-github text-dark"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="text-center mx-lg-3 mx-2 mt-lg-0 mt-4">
              <div className="fw-bold th-20 mb-3">
                Scan the QR to Download the App
              </div>
              <img src={dwiqr} alt="dwiqr" height="200px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
