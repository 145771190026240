import React from "react";
import AnujImage from "../Assets/img/anuj2.png";
import htmlsvg from "../Assets/img/html.svg";
import csssvg from "../Assets/img/css.svg";
import jssvg from "../Assets/img/js.svg";
import reactsvg from "../Assets/img/react.svg";
import reactNativesvg from "../Assets/img/react-native.svg";
import bootstrapsvg from "../Assets/img/bootstrap.svg";
import djangosvg from "../Assets/img/django.svg";
import nodejssvg from "../Assets/img/nodejs-1.svg";

const Home = () => {
  return (
    <div className="pt-4" style={{ backgroundColor: "#f9f9f9" }} id="home">
      <div className="p-lg-5 p-2 mt-lg-5 mt-5">
        <div className="container text-center text-lg-start">
          <div className="row">
            <div className="col-md-6">
              <img
                src={AnujImage}
                className="anujimage"
                width="500"
                height="350"
                alt="Anuj"
              />
            </div>
            <div className="col-md-6 mt-5 pt-lg-5 px-3">
              <div className="fw-bold th-36">Full Stack React Developer</div>
              <div className="th-20 mt-3 text-secondary title">
                Hi, I'm Anuj Kumar. A passionate Full Stack React Developer from
                Himachal Pradesh, India. 📍
              </div>
              <div className="mt-3">
                <a
                  href="https://www.linkedin.com/in/anuj-kumar-3979661b5/"
                  target="_blank" rel="noreferrer"
                >
                  <span className="m-2 ms-0 fs-2">
                    <i className="fab fa-linkedin"></i>
                  </span>
                </a>
                <a href="https://github.com/anujkumar09062001" target="_blank" rel="noreferrer">
                  <span className="m-2 fs-2">
                    <i className="fab fa-github"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container p-lg-5 p-2 text-center text-lg-start">
          <div className="row mt-lg-0 align-items-center">
            <div className="col-lg-2 fw-bold th-20 mt-lg-0 mt-4">
              Tech Stack{" "}
            </div>
            <div
              className="col-lg-10 d-flex flex-wrap mt-lg-0 mt-4
          justify-content-lg-start justify-content-center"
            >
              <div className="svg th-br-50">
                <img src={htmlsvg} className="svgimg"
                  alt="HTML" />
              </div>
              <div className="svg th-br-50">
                <img src={csssvg} className="svgimg"
                  alt="CSS" />
              </div>
              <div className="svg th-br-50">
                <img src={jssvg} className="svgimg"
                  alt="Javascript" />
              </div>
              <div className="svg th-br-50">
                <img src={reactsvg} className="svgimg"
                  alt="React" />
              </div>
              <div className="svg th-br-50">
                <img src={reactNativesvg} className="svgimg"
                  alt="React Native" />
              </div>
              <div className="svg th-br-50">
                <img src={bootstrapsvg} className="svgimg"
                  alt="Bootstrap" />
              </div>
              <div className="svg th-br-50">
                <img src={djangosvg} className="svgimg"
                  alt="Django" />
              </div>
              <div className="svg th-br-50">
                <img src={nodejssvg} className="svgimg"
                  alt="Nodejs" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
