import React from "react";
import AboutImg from "../Assets/img/aboutimg.jpg";

const About = () => {
  return (
    <div className="container mb-5" id="about">
      <div className="row p-lg-5 p-4">
        <div className="col-lg-6">
          <img
            src={AboutImg}
            className="th-br-20"
            style={{ maxWidth: "100%", height: "auto" }}
            alt="About"
          />
        </div>
        <div className="col-lg-6 mt-lg-0 mt-4 px-lg-3">
          <div className="text-primary th-24 fw-bold">ABOUT ME</div>
          <div className="th-24 fw-bold mt-2">
            A dedicated Full Stack Developer from Himachal Pradesh, India 📍
          </div>
          <div className="text-secondary mt-3 th-20">
            As a Full Stack Developer, I possess an impressive arsenal of
            skills in HTML, CSS, JavaScript, React, React Native, Bootstrap, Django and Nodejs. I excel
            in designing and maintaining responsive websites that offer a smooth
            user experience. My expertise lies in crafting dynamic, engaging
            interfaces through writing clean and optimized code and utilizing
            cutting-edge development tools and techniques. I am also a team
            player who thrives in collaborating with cross-functional teams to
            produce outstanding web applications.
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
